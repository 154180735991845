<template>
  <v-container fluid>
    <v-row>
      <!--menu-->
      <v-col cols="12" sm="12" md="3">
        <b-card
          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          class="mb-1"
          no-body
        >
          <b-card-header class="p-0" header-tag="header" role="tab">
            <v-list-item
              @click="opened = !opened"
              class="ma-0"
              v-b-toggle.global-settings
            >
              <v-list-item-title
                :class="[
                  this.$vuetify.theme.dark ? '' : '',
                  this.opened ? 'primary--text text-wrap' : 'text-wrap',
                ]"
              >
                {{ this.$t("settings.lang_priceCheckInterface") }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="!this.opened">keyboard_arrow_down</v-icon>
                <v-icon v-if="this.opened">keyboard_arrow_up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </b-card-header>

          <b-collapse
            :visible="this.opened"
            accordion="global-settings"
            id="global-settings"
            role="tabpanel"
          >
            <b-card-body class="pa-0">
              <b-list-group flush>
                <b-list-group-item
                  :class="[
                    this.$vuetify.theme.dark ? 'dark-bg' : '',
                    this.currentTab === 'colors' ? 'primary--text' : '',
                  ]"
                  @click="handleClick('colors')"
                  class="items"
                >
                  {{ this.$t("tablebee.lang_theme_colors") }}
                </b-list-group-item>

                <b-list-group-item
                  :class="[
                    this.$vuetify.theme.dark ? 'dark-bg' : '',
                    this.currentTab === 'text_colors' ? 'primary--text' : '',
                  ]"
                  @click="handleClick('text_colors')"
                  class="items"
                >
                  {{ this.$t("tablebee.lang_text_settings") }}
                </b-list-group-item>

                <b-list-group-item
                  :class="[
                    this.$vuetify.theme.dark ? 'dark-bg' : '',
                    this.currentTab === 'logo' ? 'primary--text' : '',
                  ]"
                  @click="handleClick('logo')"
                  class="items"
                >
                  {{ this.$t("Logo") }}
                </b-list-group-item>

                <b-list-group-item
                  :class="[
                    this.$vuetify.theme.dark ? 'dark-bg' : '',
                    this.currentTab === 'slideshow' ? 'primary--text' : '',
                  ]"
                  @click="handleClick('slideshow')"
                  class="items"
                >
                  {{ this.$t("generic.lang_slideShow") }}
                </b-list-group-item>

                <b-list-group-item
                  :class="[
                    this.$vuetify.theme.dark ? 'dark-bg' : '',
                    this.currentTab === 'messages' ? 'primary--text' : '',
                  ]"
                  @click="handleClick('messages')"
                  class="items"
                >
                  {{ this.$t("generic.lang_gui_messages") }}
                </b-list-group-item>

                <b-list-group-item
                  :class="[
                    this.$vuetify.theme.dark ? 'dark-bg' : '',
                    this.currentTab === 'general_settings'
                      ? 'primary--text'
                      : '',
                  ]"
                  @click="handleClick('general_settings')"
                  class="items"
                >
                  {{ this.$t("generic.lang_globalSettings") }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </v-col>

      <!--content-->
      <v-col cols="12" sm="12" md="9">
        <v-card>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col>
                  <template>
                    <div role="tablist">
                      <price-check-colors-component
                        :colors="template.theme_colors"
                        v-if="this.currentTab === 'colors'"
                      />

                      <price-check-text-colors-component
                        :texts="template.text_settings"
                        v-if="this.currentTab === 'text_colors'"
                      />

                      <!-- start images -->
                      <price-check-logo-component
                        :logo="template.logo"
                        @getTemplate="getTemplate"
                        v-if="this.currentTab === 'logo'"
                      />
                      <!-- end images -->

                      <!-- start images -->
                      <price-check-slideshow-component
                        :slideshow_images="template.slideshow_images"
                        @getTemplate="getTemplate"
                        v-if="this.currentTab === 'slideshow'"
                      />
                      <!-- end images -->

                      <!-- start of messages -->
                      <price-check-messages-component
                        :messages="template.messages"
                        v-if="this.currentTab === 'messages'"
                      />
                      <!-- end of messages -->

                      <!--start: help-->
                      <price-check-general-settings-component
                        :settings="template.settings"
                        :configToken="template.qrCode"
                        v-if="this.currentTab === 'general_settings'"
                      />
                      <!--end: help-->
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import { Events } from "@/plugins/events";
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import PriceCheckColorsComponent from "@/components/settings/cashier/priceCheckTerminal/design/priceCheckColorsComponent";
import PriceCheckTextColorsComponent from "@/components/settings/cashier/priceCheckTerminal/design/priceCheckTextColorsComponent";
import PriceCheckLogoComponent from "@/components/settings/cashier/priceCheckTerminal/design/priceCheckLogoComponent";
import PriceCheckSlideshowComponent from "@/components/settings/cashier/priceCheckTerminal/design/priceCheckSlideshowComponent";
import PriceCheckMessagesComponent from "@/components/settings/cashier/priceCheckTerminal/design/priceCheckMessagesComponent";
import PriceCheckGeneralSettingsComponent from "@/components/settings/cashier/priceCheckTerminal/design/priceCheckGeneralSettingsComponent";

export default {
  name: "Template",
  components: {
    PriceCheckColorsComponent,
    PriceCheckTextColorsComponent,
    PriceCheckLogoComponent,
    PriceCheckSlideshowComponent,
    PriceCheckMessagesComponent,
    PriceCheckGeneralSettingsComponent,
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: false,
      opened: true,
      currentTab: "colors",
      template: {
        theme_colors: {
          background: null,
          slideshow_footer: null,
          product_card: null,
        },
        text_settings: {
          fontFamily: null,
          headLine1: null,
          headLine2: null,
          headLine3: null,
          productName: null,
          productPrice: null,
        },
        logo: "",
        slideshow_images: [],
        messages: {
          welcomeMsg: "",
          welcomeMsg_en: "",
          welcomeMsg_fr: "",
          welcomeMsg_ar: "",
        },
        settings: {
          slideshowDuration: 0,
          productDisplayDuration: 0,
        },
      },
    };
  },
  methods: {
    getTemplate() {
      this.axios
        .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.GET, {
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.template.theme_colors) {
              this.template.theme_colors = JSON.parse(
                res.data.template.theme_colors
              );
            }
            if (res.data.template.text_settings) {
              this.template.text_settings = JSON.parse(
                res.data.template.text_settings
              );
            }
            if (res.data.template.logo) {
              let logo = JSON.parse(res.data.template.logo);
              this.template.logo = logo[0];
            }
            if (res.data.template.slideshow_images) {
              this.template.slideshow_images =
                res.data.template.slideshow_images;
            }
            if (res.data.template.messages) {
              this.template.messages = res.data.template.messages;
            }
            if (res.data.template.settings) {
              this.template.settings = JSON.parse(res.data.template.settings);
            }

            this.template.qrCode = res.data.template.qrCode;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        });
    },
    handleClick(newTab) {
      if (newTab && newTab.length > 0) {
        this.currentTab = newTab;
        this.$router.push({ query: { tab: newTab } });
      }
    },
  },
  mounted() {
    this.getTemplate();
    this.handleClick(this.$route.query.tab);
  },
};
</script>

<style scoped>
.items {
  cursor: pointer;
  user-select: none;
}
</style>
