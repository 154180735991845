<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title class="card-header card-header-tab bg-white border-0 transparent">
      <div :class="[this.$vuetify.theme.dark ? 'white--text' : '']" class="card-title pt-3">
        {{ $t("generic.lang_globalSettings") }}
      </div>
    </v-card-title>

    <v-divider class="ma-0" />

    <v-card-text>
      <v-form ref="form" v-model="valide" lazy-validation>
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <p>Slideshow</p>
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                @keypress="acceptOnlyPositiveNumbers" autocomplete="off" :label="$t('generic.lang_durationInSeconds')"
                dense outlined min="0" type="number" v-model="settings.slideshowDuration"></v-text-field>
            </v-col>
            <v-col cols="12">
              <p>{{ $t("generic.lang_productDisplay") }}</p>
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                @keypress="acceptOnlyPositiveNumbers" autocomplete="off" :label="$t('generic.lang_durationInSeconds')"
                dense outlined min="0" type="number" v-model="settings.productDisplayDuration"></v-text-field>
            </v-col>
            <v-col cols="12" align-self="center">
              <v-btn class="bg-primary text-light" :loading="loading" @click="printConfigQR">
                <v-icon class="ma-1">visibility</v-icon>
                {{ $t("generic.lang_printConfigQR") }}
              </v-btn>
              <!-- html2pdf -->
              <div :v-show="false">
                <vue-html2pdf :pdf-quality="2" :preview-modal="false" :show-layout="false" filename="Price checker Token QR"
                  pdf-format="a6" :paginate-elements-by-height="1400" pdf-orientation="landscape" ref="html2Pdf">
                  <section slot="pdf-content">
                    <qr-code class="qrCodeContainer" style="position: relative !important;" ref="qrCodeContainer"
                      :text="configToken || ''" :size="250">
                    </qr-code>
                  </section>
                </vue-html2pdf>
              </div>

              <v-btn class="bg-primary text-light" :loading="loading" @click="printConfigPDFQR">
                <v-icon class="ma-1">download</v-icon>
                {{ $t("generic.lang_downloadConfigQR") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="border-top">
      <v-spacer />
      <v-btn :disabled="loading || !valide" @click="update()" color="success">
        {{ $t("generic.lang_apply") }}
      </v-btn>
    </v-card-actions>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
        :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidth" />
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import validation from "../../../../../mixins/validation/validation";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueHtml2pdf from 'vue-html2pdf'
import { mapState } from "vuex";
import { printDataFromPrinter } from "@/plugins/printing/printerController";
import {geol} from "@/main";
export default {
  name: "priceCheckGeneralSettingsComponent",
  components: {
    'qr-code':VueQRCodeComponent,
    'vue-html2pdf': VueHtml2pdf
  },
  mixins: [mixin, validation],
  props: {
    configToken: {
      type: String,
    },
    settings: {
      type: Object,
      required: false,
      // default: () => {},
      default() {
        return {
          slideshowDuration: 0,
          productDisplayDuration: 0,
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      valide: false,
      visible: false,
    };
  },
  computed:{
    ...mapState(["api"]),
    ...mapState("printer", {
      printers: (state) => state.printers,
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
  },
  methods: {
    acceptOnlyPositiveNumbers(event) {
      if (isNaN(event.key)) event.preventDefault();
    },
    copyToClipBoard(){
      navigator.clipboard.writeText(this.configToken);
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_copy2Clipboard'),
        color: "info"
      });
    },
    printConfigQR() {
      let printData = [];

      printData.push({ align: "center" });
      printData.push({ text: this.$t('generic.lang_priceCheckerConfigTokenPleaseScan').replaceAll("\\n",'\n') });
      printData.push({ qrCode: this.configToken });
      printData.push({ cut: true });

      this.loading = true;

      printDataFromPrinter(this.invoicePrinter, printData)
        .then((res) => {})
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_anErrorOccurredPLSTryAgain"),
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    async printConfigPDFQR() {
      this.loading = true;
      await this.$refs.html2Pdf.generatePdf()
      this.loading = false;
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATE.GENERAL_SETTINGS,
          {
            templateID: this.$route.params.id,
            settings: JSON.stringify(this.settings),
          }
        )
        .then((res) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_updatedSuccessfully"),
            color: "success",
          });
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.qrCodeContainer img{
  margin-top: 75px !important;
  margin-left: 150px !important;
}
</style>
